<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 text-center px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full"
  >
    <div class="flex items-center justify-around text-md md:text-lg">
      <div class="">
        {{ name }}
        <div class="text-lg md:text-xl font-medium">
          <span> {{ growth }} </span>
        </div>
      </div>
      <div>
        {{ secondName }}
        <div class="text-lg md:text-xl font-medium">
          <span> {{ secondGrowth }} </span>
        </div>
      </div>
    </div>
    <div class="my-2 mx-auto">
      <apexchart
        type="pie"
        :options="chartOptions"
        :series="chartSeries"
      ></apexchart>
    </div>
    <!-- Data source label -->
    <div class="text-left">
      <span>{{ dataSrc }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import axios from '../utils/privateApiInstance';
import { formatNumber } from '@/utils/helperFunctions';
import { useTeamStore } from '@/stores/teamStore';

const props = defineProps({
  fromDate: {
    type: String,
    required: true,
  },
  toDate: {
    type: String,
    required: true,
  },
});
// Reactive variables
const name = 'Active Users';
const secondName = 'New Users';
const dataSrc = 'Google Analytics';
const growth = ref('');
const secondGrowth = ref('');
const data = ref();

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);

// Chart configuration options
const chartOptions = computed(() => ({
  chart: {
    id: 'users-chart',
    type: 'pie',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: ['#fbbf24', '#f59e0b'],
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'bottom',
    labels: {
      useSeriesColors: true,
    },
  },
  tooltip: {
    theme: 'dark',
  },
  noData: {
    text: 'Waiting for data...',
  },
  labels: ['New Users', 'Active Users'], // Custom labels for pie chart slices
}));

// Chart series data
const chartSeries = ref([0, 0]); // Placeholder data

// Fetch data from API
const fetchData = async () => {
  try {
    const response = await axios.get('/google-analytics/users-metrics', {
      params: {
        team_id: currentTeam.value ? currentTeam.value.id : '',
        start_date: props.fromDate,
        end_date: props.toDate,
      },
    });
    data.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    data.value = [];
  }
};

watch(
  () => [props.fromDate, props.toDate], // Watch for changes in these values
  async () => {
    await fetchData(); // Ensure DOM updates before calling API
  },
  { immediate: true },
);

// Watcher to update chart data and options
watch(
  () => data?.value,
  (newData) => {
    if (Array.isArray(newData)) {
      // Extract active and new users data
      const newUsers = newData.map((item) => item[1]);
      const activeUsers = newData.map((item) => item[0]);

      // Update growth values
      secondGrowth.value = formatNumber(newUsers);
      growth.value = formatNumber(activeUsers);

      // Update chart series
      chartSeries.value = [...newUsers, ...activeUsers];
    }
  },
  { immediate: true },
);
</script>
