<template>
  <MetricCard
    :fromDate="fromDate"
    :toDate="toDate"
    apiEndpoint="/google-analytics/total-users"
    metricName="Total Users"
    :formatData="formatUsers"
    srcName="Google Analytics"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import MetricCard from '../MetricCard.vue';

const props = defineProps({
  fromDate: String,
  toDate: String,
});

const formatUsers = (data) => formatNumber(data.data);
</script>
