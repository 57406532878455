<script setup>
import { computed, ref } from 'vue';
import ClickDateGADS from '@/components/ads/ClickDateGADS.vue';
import ConversionDateGADS from '@/components/ads/ConversionDateGADS.vue';
import CPCDateGADS from '@/components/ads/CPCDateGADS.vue';
import CRDateGADS from '@/components/ads/CRDateGADS.vue';
import CTRDateGADS from '@/components/ads/CTRDateGADS.vue';
import ImpDateGADS from '@/components/ads/ImpDateGADS.vue';
import TotalSpentDateGADS from '@/components/ads/TotalSpentDateGADS.vue';
import CPCVsCTRGADS from '@/components/CPCVsCTRGADS.vue';
import ImpressionAndClick from '@/components/ImpressionAndClick.vue';
import ClicksDateGSC from '@/components/search/ClicksDateGSC.vue';
import CTRDateMatrixGSC from '@/components/search/CTRDateMatrixGSC.vue';
import ImpDateGSC from '@/components/search/ImpDateGSC.vue';
import SessionTypeDonut from '@/components/SessionTypeDonut.vue';
import EngagedSessionDateGSTAT from '@/components/stats/EngagedSessionDateGSTAT.vue';
import NewUserDateGSTAT from '@/components/stats/NewUserDateGSTAT.vue';
import SessionDateGSTAT from '@/components/stats/SessionDate.GSTAT.vue';
import TotalUserDateGSTAT from '@/components/stats/TotalUserDateGSTAT.vue';
import UserMatrix from '@/components/UserMatrix.vue';
import DatePicker from '@/components/DatePicker.vue';
import { calculateDate } from '@/utils/helperFunctions';

const props = defineProps({
  teamName: {
    type: String,
    required: true,
  },
});

// Set default dates to Past 7 days
const defaultStartDate = calculateDate(-7);
const defaultEndDate = calculateDate(0);

const startDate = ref(defaultStartDate);
const endDate = ref(defaultEndDate);

const presets = [
  {
    label: 'Past 7 days',
    startDate: calculateDate(-7),
    endDate: calculateDate(0),
  },
  {
    label: 'Past 14 days',
    startDate: calculateDate(-14),
    endDate: calculateDate(0),
  },
  {
    label: 'Last 30 days',
    startDate: calculateDate(-30),
    endDate: calculateDate(0),
  },
  {
    label: 'Last 90 days',
    startDate: calculateDate(-90),
    endDate: calculateDate(0),
  },
];

const isModalOpen = ref(false);

const formattedDateRange = computed(() => {
  if (startDate.value && endDate.value) {
    return `${startDate.value} - ${endDate.value}`;
  } else {
    return 'Select a Date Range';
  }
});

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const updateStartDate = (newStartDate) => {
  startDate.value = newStartDate;
};

const updateEndDate = (newEndDate) => {
  endDate.value = newEndDate;
};
</script>

<template>
  <div
    class="flex justify-between px-3 bg-gray-100 pt-4 dark:bg-black items-center"
  >
    <div class="text-2xl font-semibold dark:text-white">
      {{ props.teamName }}
    </div>
    <button
      @click="openModal"
      class="border p-2 rounded dark:bg-gray-950 dark:text-white border-gray-700 text-xs md:text-sm flex items-center"
    >
      <span class="mr-2">{{ formattedDateRange }}</span>
      <svg
        :class="{ 'rotate-180': isModalOpen }"
        class="transition-transform"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 9l-7 7-7-7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>

  <!-- DatePicker Component -->
  <DatePicker
    :startDate="startDate"
    :endDate="endDate"
    :presets="presets"
    :isModalOpen="isModalOpen"
    @update:startDate="updateStartDate"
    @update:endDate="updateEndDate"
    @close="closeModal"
  />

  <div
    class="bg-gray-100 dark:bg-black p-3 text-md dark:text-white font-semibold grid grid-cols-1 md:grid-cols-2 gap-5 h-full"
  >
    <div class="flex flex-col">
      <h2 class="text-lg font-bold mb-3">ADS PERFORMANCE METRICS</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-5 gap-3">
        <div>
          <TotalSpentDateGADS :fromDate="startDate" :toDate="endDate" />
        </div>
        <div>
          <ConversionDateGADS :fromDate="startDate" :toDate="endDate" />
        </div>
        <div><CRDateGADS :fromDate="startDate" :toDate="endDate" /></div>
        <div><CPCDateGADS :fromDate="startDate" :toDate="endDate" /></div>
        <div class="col-start-1 row-start-3">
          <CTRDateGADS :fromDate="startDate" :toDate="endDate" />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <CPCVsCTRGADS />
        </div>
        <div class="col-span-2 row-span-2 row-start-4">
          <ImpressionAndClick />
        </div>
        <div class="col-start-3 row-start-4">
          <ImpDateGADS :fromDate="startDate" :toDate="endDate" />
        </div>
        <div class="col-start-3 row-start-5">
          <ClickDateGADS :fromDate="startDate" :toDate="endDate" />
        </div>
      </div>
    </div>

    <div class="flex flex-col">
      <h2 class="text-lg font-bold mb-3">ORGANIC METRICS</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-5 gap-3">
        <div><ImpDateGSC :fromDate="startDate" :toDate="endDate" /></div>
        <div><ClicksDateGSC :fromDate="startDate" :toDate="endDate" /></div>
        <div><CTRDateMatrixGSC :fromDate="startDate" :toDate="endDate" /></div>
        <div><SessionDateGSTAT :fromDate="startDate" :toDate="endDate" /></div>
        <div class="col-start-1 row-start-3">
          <EngagedSessionDateGSTAT :fromDate="startDate" :toDate="endDate" />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <SessionTypeDonut :fromDate="startDate" :toDate="endDate" />
        </div>
        <div class="col-span-2 row-span-2 row-start-4">
          <UserMatrix :fromDate="startDate" :toDate="endDate" />
        </div>
        <div class="col-start-3 row-start-4">
          <NewUserDateGSTAT :fromDate="startDate" :toDate="endDate" />
        </div>
        <div class="col-start-3 row-start-5">
          <TotalUserDateGSTAT :fromDate="startDate" :toDate="endDate" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* Style for the date input fields */
input[type='date'] {
  padding: 0.5rem; /* Reduced padding */
  height: 2rem; /* Reduced height */
  width: 8rem; /* Reduced width */
  border-radius: 0.375rem; /* Adjust border-radius if needed */
}

/* Ensuring the date input icon is visible in dark theme */
.dark input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(
    1
  ); /* Invert the icon color to be visible on dark background */
  width: 1rem; /* Adjust the width of the calendar icon */
  height: 1rem; /* Adjust the height of the calendar icon */
}

/* Firefox specific styling */
.dark input[type='date']::-moz-calendar-picker-indicator {
  filter: invert(1); /* Firefox specific styling */
  width: 1rem; /* Adjust the width of the calendar icon */
  height: 1rem; /* Adjust the height of the calendar icon */
}

/* Edge specific styling */
.dark input[type='date']::-ms-calendar-picker-indicator {
  filter: invert(1); /* Edge specific styling */
  width: 1rem; /* Adjust the width of the calendar icon */
  height: 1rem; /* Adjust the height of the calendar icon */
}
</style>
