<template>
  <MetricCard
    :fromDate="fromDate"
    :toDate="toDate"
    apiEndpoint="/google-ads/impressions"
    metricName="IMPRESSIONS"
    :formatData="formatImpressions"
    srcName="Google Ads"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import MetricCard from '../MetricCard.vue';

const props = defineProps({
  fromDate: String,
  toDate: String,
});

const formatImpressions = (data) => formatNumber(data.data);
</script>
