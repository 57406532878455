<template>
  <div>
    <!-- Mobile Hamburger Button -->
    <div
      class="md:hidden fixed top-0 right-0 p-4 flex w-full justify-between dark:text-white bg-white dark:bg-black"
    >
      <a href="/dashboard" class="bg-black rounded-md py-1 px-2">
        <ApplicationMark class="h-6 w-auto" />
      </a>
      <button @click="toggleSidebar" class="p-1" aria-label="Toggle Sidebar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <!-- Sidebar -->
    <aside
      :class="[
        'fixed inset-0 top-0 left-0 bg-gray-50 dark:bg-black border-r border-gray-200 dark:border-gray-600  z-50 transition-transform md:translate-x-0 md:w-44 md:block',
        { 'translate-x-0': isSidebarOpen, '-translate-x-full': !isSidebarOpen },
      ]"
    >
      <nav class="flex flex-col gap-4 h-full p-4">
        <!-- Logo Section -->
        <div
          class="flex items-center justify-between gap-2 px-2 pb-4 border-b dark:border-gray-700 pt-2"
        >
          <a href="/dashboard" class="bg-black rounded-md py-1 px-2">
            <ApplicationMark class="h-8 w-auto" />
          </a>
          <!-- Mobile Close Button -->
          <button
            @click="toggleSidebar"
            class="md:hidden dark:text-white"
            aria-label="Close Sidebar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <!-- Links Section -->
        <div class="flex flex-col flex-grow">
          <RouterLink
            to="/dashboard"
            class="dark:text-white flex items-center space-x-1 py-2 rounded-sm px-2"
            :class="{ 'bg-gray-200 dark:bg-amber-500': isActive('/dashboard') }"
          >
            <ChartNoAxesCombined :size="20" />
            <span>Dashboard</span>
          </RouterLink>
          <RouterLink
            to="/insight"
            class="dark:text-white flex items-center space-x-1 py-2 rounded-sm px-2"
            :class="{ 'bg-gray-200 dark:bg-amber-500': isActive('/insight') }"
          >
            <Lightbulb :size="20" />
            <span>Insight</span>
          </RouterLink>
        </div>

        <!-- Profile and Logout Section -->
        <div class="flex flex-col mt-auto mb-5">
          <!-- Custom Dropdown for Teams -->
          <div>
            <CustomDropdown>
              <template #trigger>
                <button
                  type="button"
                  class="inline-flex items-center px-3 py-2 border text-sm leading-4 font-medium rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:bg-gray-50 active:bg-gray-50 transition ease-in-out duration-150 dark:bg-gray-800 dark:text-gray-100 w-full justify-between bg-white"
                >
                  {{ currentTeamName }}
                  <svg
                    class="ms-2 -me-0.5 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    />
                  </svg>
                </button>
              </template>
              <template #content>
                <div>
                  <!-- Team Management -->
                  <div
                    class="block px-4 py-2 text-xs text-gray-400 dark:text-gray-50"
                  >
                    Manage Team
                  </div>

                  <RouterLink
                    to="/team"
                    class="block px-4 py-2 text-sm text-gray-800 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    Team Settings
                  </RouterLink>

                  <RouterLink
                    to="/create-team"
                    class="block px-4 py-2 text-sm text-gray-800 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700"
                  >
                    Create New Team
                  </RouterLink>

                  <div class="border-t border-gray-200" />
                  <div
                    class="block px-4 py-2 text-xs text-gray-400 dark:text-gray-50"
                  >
                    Switch Teams
                  </div>

                  <!-- Display List of Teams -->
                  <div v-for="team in teams" :key="team.id">
                    <button
                      type="button"
                      @click.prevent="switchToTeam(team)"
                      class="flex items-center px-4 py-2 text-sm text-gray-800 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700 w-full text-left"
                    >
                      <div class="flex items-center gap-2">
                        <div>{{ team.name }}</div>
                        <svg
                          v-if="currentTeamId === team.id"
                          class="me-2 h-4 w-4 text-green-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>
              </template>
            </CustomDropdown>
          </div>
          <div
            class="flex items-center space-x-2 py-2 w-full justify-between pr-4"
          >
            <!-- Profile Link -->
            <RouterLink
              to="/profile"
              class="dark:text-white flex items-center space-x-1 py-2 rounded-sm pl-2"
            >
              <User :size="20" />
              <span>Profile</span>
            </RouterLink>
            <!-- Theme Toggle Switch -->
            <label
              class="container flex justify-center items-center relative cursor-pointer w-fit"
            >
              <input
                type="checkbox"
                v-model="isDarkMode"
                @change="toggleTheme"
              />
              <svg
                viewBox="0 0 384 512"
                width="1.2rem"
                height="1.2rem"
                xmlns="http://www.w3.org/2000/svg"
                class="moon"
              >
                <path
                  d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2rem"
                height="1.2rem"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="sun"
              >
                <circle cx="12" cy="12" r="4" />
                <path d="M12 2v2" />
                <path d="M12 20v2" />
                <path d="m4.93 4.93 1.41 1.41" />
                <path d="m17.66 17.66 1.41 1.41" />
                <path d="M2 12h2" />
                <path d="M20 12h2" />
                <path d="m6.34 17.66-1.41 1.41" />
                <path d="m19.07 4.93-1.41 1.41" />
              </svg>
            </label>
          </div>
          <!-- Logout Link -->
          <div
            class="dark:text-white flex items-center space-x-1 py-2 rounded-sm px-2"
          >
            <LogOut :size="20" />
            <span><Logout /></span>
          </div>
        </div>
      </nav>
    </aside>

    <!-- Content Area -->
    <div
      :class="[
        'md:ml-44 flex-1 transition-transform ',
        { 'ml-0 ': !isSidebarOpen },
      ]"
    >
      <slot />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CustomDropdown from '@/components/CustomDropdown.vue';
import { ChartNoAxesCombined, Lightbulb, LogOut, User } from 'lucide-vue-next';
import { useTeamStore } from '@/stores/teamStore';
import { useUserStore } from '@/stores/userStore';
import Logout from '@/pages/Auth/Logout.vue';
import axios from '../utils/privateApiInstance';
import ApplicationMark from '@/components/ApplicationMark.vue';

const userStore = useUserStore();
const teamStore = useTeamStore();
const isSidebarOpen = ref(false);
const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};

const isActive = (path) => useRoute().path === path;
const currentTeamId = ref(null); // Replace with your actual logic to get current team ID
const currentTeamName = ref(''); // Replace with your actual logic to get current team name
const teams = ref([]); // Replace with your actual logic to get list of teams

watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeamId.value = newTeam ? newTeam.id : null;
    currentTeamName.value = newTeam ? newTeam.name : '';
  },
  { immediate: true, deep: true },
);

watch(
  () => teamStore.teams,
  (newTeams) => {
    teams.value = [...newTeams];
  },
  { immediate: true, deep: true },
);

const switchToTeam = (team) => {
  teamStore.setCurrentTeam(team);
  toast(`Switched to ${team.name} team`, {
    theme: 'light',
    transition: 'zoom',
    type: 'info',
    autoClose: 1000,
    position: 'top-center',
    dangerouslyHTMLString: true,
  });
};

const fetchData = async () => {
  const response = await axios.get('/settings/profile');

  if (response.data) {
    const { teams, ...userData } = response.data;
    userStore.setUser(userData);
    if (teamStore.teams.length == 0) {
      teamStore.setTeams(teams);
    }
  }
};
onMounted(() => {
  fetchData();
});
const isDarkMode = ref(localStorage.getItem('theme') === 'dark' || 'light');
const toggleTheme = () => {
  localStorage.setItem('theme', isDarkMode.value ? 'dark' : 'light');
  document.documentElement.classList.toggle('dark', isDarkMode.value);
};

// Watch for changes in localStorage and update the theme
watch(
  () => localStorage.getItem('theme'),
  (newTheme) => {
    isDarkMode.value = newTheme === 'dark';
    document.documentElement.classList.toggle('dark', isDarkMode.value);
  },
  {
    immediate: true,
  },
);
</script>

<style scoped>
.container {
  --color: #787878;
  user-select: none;
  fill: var(--color);
}

.container .moon {
  position: absolute;
  animation: keyframes-fill 0.5s;
}

.container .sun {
  position: absolute;
  display: none;
  animation: keyframes-fill 0.5s;
}

/* ------ On check event ------ */
.container input:checked ~ .moon {
  display: none;
}

.container input:checked ~ .sun {
  display: block;
}

/* ------ Hide the default checkbox ------ */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* ------ Animation ------ */
@keyframes keyframes-fill {
  0% {
    transform: rotate(-360deg) scale(0);
    opacity: 0;
  }

  75% {
    transform: rotate(25deg);
  }
}
</style>
