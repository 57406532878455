import './assets/main.css';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import VueApexCharts from 'vue3-apexcharts';
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueCookies from 'vue3-cookies';
import App from './App.vue';
import router from './router';
import { useCookies } from 'vue3-cookies';
import * as Sentry from '@sentry/vue';

const app = createApp(App);
const queryClient = new QueryClient();

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
  });
}

app.use(createPinia());
app.use(router);
app.use(Vue3Toastify, {
  autoclose: 1000,
});
app.use(VueCookies, {
  path: '/',
  secure: true,
});
app.use(useCookies);
app.use(VueApexCharts);
app.use(VueQueryPlugin, { queryClient });
app.mount('#app');
