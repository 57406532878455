<template>
  <div
    class="grid gap-1 md:gap-3 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1"
  >
    <!-- Loop through each item in propData and render a ConnectCardButton for each -->
    <div v-for="item in propData" :key="item.name" class="p-1">
      <!-- Pass item data to ConnectCardButton component -->
      <ConnectCardButton :data="item" />
    </div>
  </div>
</template>

<script setup>
import ConnectCardButton from '@/components/ConnectCardButton.vue';
import { useConnectorStore } from '@/stores/connectorStore';
import { useTeamStore } from '@/stores/teamStore';
import axios from '@/utils/fivetranApiInstance';
import axiosInstance from '@/utils/privateApiInstance';
import { ref, watch } from 'vue';
const teamStore = useTeamStore();
const connectorStore = useConnectorStore();
// Define an array of data objects, each representing a service with relevant information
const propData = [
  {
    service: 'google_ads',
    schemaName: 'g_ads_',
    name: 'Google Ads',
  },
  {
    service: 'google_analytics_4',
    schemaName: 'g_stat_',
    name: 'Google Analytics',
  },
  {
    service: 'facebook_ads',
    schemaName: 'f_ads_',
    name: 'Facebook Ads',
  },
  {
    service: 'google_search_console',
    schemaName: 'g_search_c_',
    name: 'Google Search Console',
  },
  {
    service: 'amazon_ads',
    schemaName: 'amazon_ads_',
    name: 'Amazon Ads',
  },
];
// Function to update connector schema in your backend
const updateConnectorSchema = async (connectorId, schema) => {
  try {
    const response = await axiosInstance.patch(
      '/settings/set-connector-schema',
      {
        schema_name: schema,
        connector_id: connectorId,
      },
      {
        params: {
          team_id: teamStore.current_team.id,
        },
      },
    );
    if (response.status == 200) {
      teamStore.setTeams(response.data?.team);
    }
  } catch (error) {
    console.error('Error updating connector schema. Please try again.');
  }
};
// Function to check and update connector schemas
const checkAndUpdateConnectors = async (connectors) => {
  for (const connector of connectors) {
    if (!connector.schema_name && connector.connector_id) {
      try {
        const response = await axios.get(
          `/connectors/${connector.connector_id}`,
        );
        const data = response.data?.data;

        if (data?.status?.setup_state != 'incomplete') {
          await updateConnectorSchema(connector.connector_id, data.schema);
        }
      } catch (error) {
        console.error('Error fetching connector details:', error);
      }
    } else if (!connector.connector_id) {
      console.log(
        `Connector with missing ID and schema name was skipped:`,
        connector,
      );
    }
  }
};
const currentTeam = ref(teamStore.current_team);

// Watch for changes in the current team and handle schema updates
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;

    // Clear existing connector IDs in the store
    Object.keys(connectorStore.connectorId).forEach((key) => {
      connectorStore.setConnectorId(key, '');
    });

    if (newTeam?.connectors) {
      // Check if any connectors are missing `schema_name`
      const missingDataConnectors = newTeam.connectors.filter(
        (connector) => !connector.schema_name,
      );

      // Check for connectors with missing schema or ID
      if (missingDataConnectors.length > 0) {
        checkAndUpdateConnectors(missingDataConnectors);
      }
      // Update completed status based on connectors
      propData.forEach((item) => {
        const connectorWithType = newTeam.connectors.find(
          (connector) => connector.connector_type === item.service,
        );
        if (connectorWithType) {
          connectorStore.setConnectorId(
            item.service,
            connectorWithType.connector_id,
          );
        }
        const completed = connectorWithType
          ? !!connectorWithType.schema_name
          : false;
        connectorStore.setCompleted(item.service, completed);
      });
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>

<style scoped></style>
