export function formatNumber(num) {
  if (num >= 1_000_000) {
    // Convert to millions
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= 1_000) {
    // Convert to thousands
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    // Return the number as-is if it's less than 1,000
    return num?.toString();
  }
}
export function calculateDate(days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.toISOString().split('T')[0];
}
