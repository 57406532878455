<template>
  <div>
    <label class="block text-sm font-medium text-gray-700 dark:text-gray-300"
      >Verification Code</label
    >
    <div class="flex justify-center">
      <input
        v-for="(digit, index) in otpcode"
        :key="index"
        type="text"
        class="max-w-12 max-h-12 border border-gray-300 bg-gray-100 text-black rounded-md text-center text-2xl focus:outline-none focus:ring-2 focus:ring-blue-500 m-2 p-1"
        maxlength="1"
        v-model="otpcode[index]"
        @input="handleChange($event, index)"
        @keydown="handleKeyDown($event, index)"
        ref="inputRefs"
      />
    </div>
    <p v-if="error" class="text-red-700 text-xs p-1">*{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
  otpcode: {
    type: Array,
    required: true
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:otpcode', 'update:error', 'update:errorMessage'])

const inputRefs = ref([])

// Handle change in OTP input
const handleChange = (event, index) => {
  const value = event.target.value
  if (!/^\d?$/.test(value)) return // Ensure only numbers are entered

  emit('update:otpcode', [
    ...props.otpcode.slice(0, index),
    value,
    ...props.otpcode.slice(index + 1)
  ])

  // Focus next input field
  if (value !== '' && index < 5) {
    inputRefs.value[index + 1]?.focus()
  }
}

// Handle key down event in OTP input
const handleKeyDown = (event, index) => {
  if (event.key === 'Backspace' && !props.otpcode[index] && index > 0) {
    inputRefs.value[index - 1]?.focus()
  }
}

// Initialize refs
onMounted(() => {
  inputRefs.value = Array(6)
    .fill(null)
    .map((_, index) => inputRefs.value[index])
})
</script>
