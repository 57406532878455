<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow"
  >
    <!-- Header section with title -->
    <div class="text-md md:text-lg">Session Medium</div>
    <!-- ApexChart component with adjusted height -->
    <div class="flex-1 flex items-center justify-center">
      <apexchart
        type="donut"
        :options="chartOptions"
        :series="chartSeries"
        class="w-full h-full"
      ></apexchart>
    </div>
    <!-- Data source label -->
    <div class="text-left text-md">Google Analytics</div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import axios from '../utils/privateApiInstance';
import { useTeamStore } from '@/stores/teamStore';
import { formatNumber } from '@/utils/helperFunctions';
const props = defineProps({
  fromDate: {
    type: String,
    required: true,
  },
  toDate: {
    type: String,
    required: true,
  },
});
// Reactive variables
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const data = ref();
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);

// Chart configuration options
const chartOptions = computed(() => ({
  chart: {
    id: 'sessions-chart',
    type: 'donut',

    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  colors: [
    '#fbbf24',
    '#f59e0b',
    '#34d399',
    '#60a5fa',
    '#e11d48',
    '#6d28d9',
    '#ef4444',
    '#10b981',
    '#6ee7b7',
    '#3b82f6',
  ],

  labels: chartLabels.value,
  legend: {
    position: 'right',
    fontSize: '15rem',
    formatter: function (seriesName, opts) {
      return [
        seriesName,
        ' - ',
        formatNumber(opts.w.globals.series[opts.seriesIndex]),
      ];
    },
    labels: {
      useSeriesColors: true,
    },
  },
  tooltip: {
    theme: 'dark',
  },
  noData: {
    text: 'Waiting for data...',
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 768, // Adjust the breakpoint as needed
      options: {
        legend: {
          position: 'bottom', // Move the legend to the bottom for small screens
          fontSize: '10rem',
          formatter: function (seriesName, opts) {
            return [seriesName];
          },
        },
      },
    },
  ],
}));
// Fetch session data from API
const fetchData = async () => {
  try {
    const response = await axios.get('/google-analytics/session-medium', {
      params: {
        team_id: currentTeam.value ? currentTeam.value.id : '',
        start_date: props.fromDate,
        end_date: props.toDate,
      },
    });

    data.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    data.value = [];
  }
};
// Chart series data
const chartSeries = ref([]); // Placeholder data
const chartLabels = ref([]); // Placeholder labels
// Watch for changes in props to refetch data
watch(
  () => [props.fromDate, props.toDate], // Watch for changes in these values
  async () => {
    await fetchData(); // Ensure DOM updates before calling API
  },
  { immediate: true },
);

// Watcher to update chart data and options
watch(
  () => data?.value,
  (newData) => {
    if (Array.isArray(newData)) {
      // // Extract session types and their counts
      const labels = newData.map((items) => items[0]);
      const series = newData.map((item) => item[1]); // Session counts
      // // Update chart data
      chartLabels.value = labels;
      chartSeries.value = series;
    }
  },
  { immediate: true },
);
</script>
