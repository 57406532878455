<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 text-center px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full"
  >
    <!-- Header section with toggle button and title -->
    <div class="">{{ currentMonth }}</div>

    <div class="flex items-center justify-around text-md md:text-lg">
      <div class="">
        {{ name }}
        <div class="text-lg md:text-xl font-medium">
          <span> {{ growth }} </span>
        </div>
      </div>
      <div>
        {{ secondName }}
        <div class="text-lg md:text-xl font-medium">
          <span> {{ secondGrowth }} </span>
        </div>
      </div>
    </div>

    <div class="my-2 mx-auto">
      <!-- Chart component -->
      <apexchart
        type="line"
        :options="chartOptions"
        :series="chartSeries"
      ></apexchart>
    </div>

    <!-- Data source label -->
    <div class="flex justify-between">
      <span>{{ dataSrc }}</span>
      <div class="text-xs text-end text-gray-500 p-1">
        <span>Last sync: {{ formattedUpdatedAt }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import axios from '../utils/privateApiInstance';
import { formatNumber } from '@/utils/helperFunctions';
import { useTeamStore } from '@/stores/teamStore';

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);

// Watch for changes in the team store and update the local ref
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);
// Constants
const dataSrc = 'Google Ads';

// Reactive variables
const name = 'Impressions';
const currentMonth = ref();
const secondName = 'Clicks';
const growth = ref('');
const secondGrowth = ref('');
const categories = ref([]);
const chartSeries = ref([
  {
    name: 'Impressions',
    data: [],
  },
  {
    name: 'Clicks',
    data: [],
  },
]);

// Define the query function
const fetchData = async () => {
  try {
    const response = await axios.get(
      '/google-ads/impression-and-clicks-metrics',
      {
        params: {
          team_id: currentTeam.value ? currentTeam.value.id : '',
        },
      },
    );
    return JSON.parse(response.data?.items.data);
  } catch (error) {
    console.error('Failed to fetch data ');
  }
};

// Use TanStack Query's useQuery hook
const { data, dataUpdatedAt } = useQuery({
  queryKey: ['impressions-and-clicks-metrics'],
  queryFn: fetchData,
  staleTime: 10 * 60 * 1000, // Data considered fresh for 10 minutes
  cacheTime: 15 * 60 * 1000, // Data cached for 15 minutes
  refetchInterval: 10 * 60 * 1000, // Refetch data every 10 minutes
});
const formattedUpdatedAt = computed(() => {
  if (dataUpdatedAt) {
    return new Date(dataUpdatedAt.value).toLocaleString('en-US', {
      timeStyle: 'short',
    });
  }
  return 'Never updated';
});

// Watch for data changes and update chart accordingly
watch(
  () => data.value,
  (newData) => {
    if (Array.isArray(newData) && newData.length > 0) {
      // Convert timestamps to readable month names
      categories.value = newData.map((item) => {
        const temp = new Date(item[0]);
        return temp.toLocaleDateString('en-GB', {
          month: 'short',
          year: 'numeric',
        });
      });
      currentMonth.value = categories.value[categories.value.length - 1];

      // Extract impressions and clicks data
      const impressions = newData.map((item) => item[1]);
      const clicks = newData.map((item) => item[2]);

      // Update growth values
      if (impressions.length > 0) {
        growth.value = formatNumber(impressions[impressions.length - 1]);
      }

      if (clicks.length > 0) {
        secondGrowth.value = formatNumber(clicks[clicks.length - 1]);
      }

      // Update chart series
      chartSeries.value = [
        {
          name: 'Impressions',
          data: impressions,
        },
        {
          name: 'Clicks',
          data: clicks,
        },
      ];
    }
  },
  { immediate: true },
);
// Chart configuration options
const chartOptions = computed(() => ({
  chart: {
    id: 'impressions-and-click-chart',
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: ['#3b82f6', '#a855f7'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    categories: categories.value, // Bind to categories
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  tooltip: {
    x: {
      formatter: function (value, { seriesIndex, dataPointIndex }) {
        // Get month name from categories and format tooltip
        const category = categories.value[dataPointIndex];
        return `${category}`;
      },
    },
    theme: 'dark',
  },
  noData: {
    text: 'Waiting for data...',
  },
  legend: {
    position: 'bottom',
    labels: {
      useSeriesColors: true,
    },
  },
}));
</script>
