import axios from 'axios';
import { useCookies } from 'vue3-cookies';
import { toast } from 'vue3-toastify';

const url = import.meta.env.VITE_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: `${url}/api`,
});

const { cookies } = useCookies();

let isRefreshing = false;
const failedQueue = [];

// Process the queue after a token refresh
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue.length = 0;
};

// Request interceptor to attach access token
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = cookies.get('authToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      if (isRefreshing) {
        // Queue the request if a refresh is in progress
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;

      try {
        const refreshToken = cookies.get('refreshToken');

        // Request new tokens
        const response = await axios.post(`${url}/api/auth/refresh`, null, {
          headers: { 'refresh-token': refreshToken },
        });

        const newAccessToken = response.data.access_token;
        const newRefreshToken = response.data.refresh_token;

        // Update tokens in cookies
        cookies.set('authToken', newAccessToken);
        cookies.set('refreshToken', newRefreshToken);

        // Update Axios default headers with the new token
        axiosInstance.defaults.headers.common['Authorization'] =
          `Bearer ${newAccessToken}`;

        // Process the queue with the new access token
        processQueue(null, newAccessToken);
        isRefreshing = false;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        cookies.remove('authToken');
        cookies.remove('refreshToken');
        processQueue(refreshError, null);
        isRefreshing = false;

        // Show toast notification
        toast.error('Your session has expired. Please log in again.');

        // Redirect to the home page
        window.location.href = '/';

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
