<template>
  <!-- Modal for Date Selection -->
  <transition name="fade">
    <div
      v-if="isModalOpen"
      class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex items-center justify-center"
    >
      <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-semibold dark:text-white">
            Select Date Range
          </h3>
          <button
            @click="closeModal"
            class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <X />
          </button>
        </div>
        <div class="mb-4">
          <div class="flex gap-2 justify-evenly">
            <div class="flex items-baseline gap-1">
              <p class="dark:text-white">From:</p>
              <input
                type="date"
                v-model="startDate"
                @change="onDateChange"
                class="border border-gray-300 rounded px-2 py-1 dark:bg-gray-950 dark:text-white dark:border-gray-700 w-full"
              />
            </div>
            <div class="flex items-baseline gap-1">
              <p class="dark:text-white">To:</p>
              <input
                type="date"
                v-model="endDate"
                @change="onDateChange"
                class="border border-gray-300 rounded px-2 py-1 dark:bg-gray-950 dark:text-white dark:border-gray-700 w-full"
              />
            </div>
          </div>
        </div>
        <div class="mt-4">
          <p class="mb-2 dark:text-white">Presets:</p>
          <div class="space-y-2">
            <button
              v-for="preset in presets"
              :key="preset.label"
              @click="applyPreset(preset)"
              class="block w-full text-left px-4 py-2 border rounded dark:bg-gray-950 dark:text-white dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              {{ preset.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, defineEmits, watch } from 'vue';
import { X } from 'lucide-vue-next';
import { toast } from 'vue3-toastify';
import { calculateDate } from '@/utils/helperFunctions';

// Define emits
const emit = defineEmits(['update:startDate', 'update:endDate', 'close']);

// Props
const props = defineProps({
  startDate: {
    type: String,
    default: '',
  },
  endDate: {
    type: String,
    default: '',
  },
  presets: {
    type: Array,
    default: () => [],
  },
  isModalOpen: {
    type: Boolean,
    default: false,
  },
});

const startDate = ref(props.startDate || calculateDate(-7));
const endDate = ref(props.endDate || calculateDate(0));

const validateDates = () => {
  const today = new Date();
  const todayDate = today.toISOString().split('T')[0]; // YYYY-MM-DD format
  if (!startDate.value || !endDate.value) {
    toast.error('Please select both start and end dates.');
    return false;
  }
  if (startDate.value > todayDate || endDate.value > todayDate) {
    startDate.value = calculateDate(-7);
    endDate.value = calculateDate(0);
    toast.error('Dates cannot be greater than today. Adjusted to past 7 days.');
    return false;
  }

  const start = new Date(startDate.value);
  const end = new Date(endDate.value);

  if (end < start) {
    endDate.value = startDate.value;
    toast.error('End date cannot be earlier than start date.');
    return false;
  }

  return true;
};

const onDateChange = () => {
  if (validateDates()) {
    emit('update:startDate', startDate.value);
    emit('update:endDate', endDate.value);
  }
};

const applyPreset = (preset) => {
  startDate.value = preset.startDate;
  endDate.value = preset.endDate;
  validateDates();
  emit('update:startDate', startDate.value);
  emit('update:endDate', endDate.value);
  closeModal();
};

const closeModal = () => {
  emit('close');
};

// Watch for changes in props to update local state
watch(
  () => props.isModalOpen,
  (newValue) => {
    if (!newValue) {
      startDate.value = props.startDate || calculateDate(-7);
      endDate.value = props.endDate || calculateDate(0);
    }
  },
);
</script>
