<template>
  <div class="md:col-span-1 flex justify-between">
    <div class="px-4 sm:px-0">
      <h3 class="text-lg font-medium text-gray-900 dark:text-gray-50">
        <slot name="title" />
      </h3>

      <p class="mt-1 text-sm text-gray-600 dark:text-gray-200">
        <slot name="description" />
      </p>
    </div>

    <div class="px-4 sm:px-0 dark:text-white">
      <slot name="aside" />
    </div>
  </div>
</template>
