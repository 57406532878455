import { defineStore } from 'pinia'

const initialCompletedState = {
  google_ads: false,
  google_analytics_4: false,
  facebook_ads: false,
  google_search_console: false,
  amazon_ads: false
}
const initialId = {
  google_ads: '',
  google_analytics_4: '',
  facebook_ads: '',
  google_search_console: '',
  amazon_ads: ''
}
export const useConnectorStore = defineStore('connector', {
  state: () => ({
    loading: false,
    completedConnectors: initialCompletedState,
    connectorId: initialId
  }),
  actions: {
    setLoading() {
      this.loading = !this.loading
    },
    setCompleted(name, status) {
      this.completedConnectors[name] = status
    },
    setConnectorId(name, id) {
      this.connectorId[name] = id
    },
    clearStore() {
      this.loading = false
      this.completedConnectors = initialCompletedState
      this.connectorId = initialId
    }
  }
})
