<template>
  <MetricCard
    :fromDate="fromDate"
    :toDate="toDate"
    apiEndpoint="/google-ads/conversion-rate"
    metricName="CONVERSION RATE"
    :formatData="formatCR"
    srcName="Google Ads"
  />
</template>

<script setup>
import MetricCard from '../MetricCard.vue';

const props = defineProps({
  fromDate: String,
  toDate: String,
});

const formatCR = (data) => data.data.toFixed(2) + '%';
</script>
