<script setup>
import { ref, watch } from 'vue';
import AuthenticationCard from '@/components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/components/AuthenticationCardLogo.vue';
import InputError from '@/components/InputError.vue';
import InputLabel from '@/components/InputLabel.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import TextInput from '@/components/TextInput.vue';
import OtpInput from '@/components/OtpInput.vue';
import { toast } from 'vue3-toastify';
import axios from '@/utils/publicApiInstance';
import { useRouter } from 'vue-router';

const router = useRouter();
// States
const showEmailForm = ref(true);
const showPasswordForm = ref(false);
const newPasswordForm = ref({
  email: '',
  password: '',
  password_confirmation: '',
  otpcode: Array(6).fill(''),
  errors: {},
  processing: false,
  recentlySuccessful: false,
});

const validate = () => {
  let isValid = true;
  // Password validation
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  if (!passwordPattern.test(newPasswordForm.value.password)) {
    newPasswordForm.value.errors.password =
      'Password does not satisfy conditions.';
    isValid = false;
  }

  // Password confirmation validation
  if (
    newPasswordForm.value.password !==
    newPasswordForm.value.password_confirmation
  ) {
    newPasswordForm.value.errors.password_confirmation =
      'Passwords do not match.';
    isValid = false;
  }
  if (newPasswordForm.value.otpcode.join('') == '') {
    newPasswordForm.value.errors.otp = true;
    isValid = false;
  }
  return isValid;
};

const requestPasswordReset = async () => {
  newPasswordForm.value.processing = true;
  newPasswordForm.value.errors = {};
  newPasswordForm.value.recentlySuccessful = false;

  try {
    const response = await axios.post('/auth/forgot-password', {
      email: newPasswordForm.value.email,
    });

    if (response.status == 200) {
      toast('OTP sent successfully', {
        type: 'success',
        position: 'top-center',
        autoClose: 1000,
        transition: 'zoom',
      });
      showEmailForm.value = false;
      showPasswordForm.value = true;
    }
  } catch (error) {
    newPasswordForm.value.errors = error.response.data.errors || {};
    toast(error.response.data.detail || 'An error occurred', {
      type: 'error',
      position: 'top-center',
      autoClose: 1000,
      transition: 'zoom',
    });
  } finally {
    newPasswordForm.value.processing = false;
  }
};

const resetPassword = async () => {
  newPasswordForm.value.processing = true;
  newPasswordForm.value.errors = {};
  newPasswordForm.value.recentlySuccessful = false;

  if (!validate()) {
    newPasswordForm.value.processing = false;
    return;
  }

  try {
    const otpString = newPasswordForm.value.otpcode.join('');
    const response = await axios.put('/auth/reset-password', {
      email: newPasswordForm.value.email,
      password: newPasswordForm.value.password,
      token: otpString,
    });
    console.log(response);

    if (response.status == 200) {
      toast('Password changed successfully', {
        type: 'success',
        position: 'top-center',
        autoClose: 1000,
        transition: 'zoom',
      });
      newPasswordForm.value.recentlySuccessful = true;
      newPasswordForm.value.password = '';
      newPasswordForm.value.password_confirmation = '';
      newPasswordForm.value.otpcode = Array(6).fill('');
      router.push({ name: 'Login' });
      showEmailForm.value = true;
      showPasswordForm.value = false;
    }
  } catch (error) {
    newPasswordForm.value.errors = error.response.data.errors || {};
    toast(error.response.data.detail || 'An error occurred', {
      type: 'error',
      position: 'top-center',
      autoClose: 1000,
      transition: 'zoom',
    });
  } finally {
    newPasswordForm.value.processing = false;
  }
};

watch(
  () => newPasswordForm.value.email,
  (newEmail) => {
    newPasswordForm.value.email = newEmail;
  },
);
</script>

<template>
  <AuthenticationCard v-if="showEmailForm">
    <template #logo>
      <AuthenticationCardLogo />
    </template>

    <div class="mb-4 text-sm text-gray-800 dark:text-gray-300">
      Forgot your password? No problem. Just let us know your email address and
      we will email you a otp for resetting your password.
    </div>

    <form @submit.prevent="requestPasswordReset">
      <div>
        <InputLabel for="email" value="Email" />
        <TextInput
          id="email"
          v-model="newPasswordForm.email"
          type="email"
          class="mt-1 block w-full"
          required
          autofocus
          autocomplete="username"
        />
        <InputError class="mt-2" :message="newPasswordForm.errors.email" />
      </div>

      <div class="flex items-center justify-end mt-4">
        <PrimaryButton
          :class="{ 'opacity-25': newPasswordForm.processing }"
          :disabled="newPasswordForm.processing"
        >
          Request OTP
        </PrimaryButton>
      </div>
    </form>
  </AuthenticationCard>

  <AuthenticationCard v-if="showPasswordForm">
    <template #logo>
      <AuthenticationCardLogo />
    </template>

    <div class="mb-4 text-sm text-gray-800 dark:text-gray-300">
      Enter your new password to reset it.
    </div>

    <form @submit.prevent="resetPassword">
      <div>
        <InputLabel for="password" value="New Password" />
        <TextInput
          id="password"
          v-model="newPasswordForm.password"
          type="password"
          class="mt-1 block w-full"
          autocomplete="new-password"
        />
        <InputError class="mt-2" :message="newPasswordForm.errors.password" />
      </div>

      <div>
        <InputLabel for="password_confirmation" value="Confirm Password" />
        <TextInput
          id="password_confirmation"
          v-model="newPasswordForm.password_confirmation"
          type="password"
          class="mt-1 block w-full"
          autocomplete="new-password"
        />
        <InputError
          class="mt-2"
          :message="newPasswordForm.errors.password_confirmation"
        />
      </div>

      <div>
        <OtpInput
          v-model:otpcode="newPasswordForm.otpcode"
          :error="!!newPasswordForm.errors.otp"
          :errorMessage="newPasswordForm.errors.otp ? 'Please Enter OTP' : ''"
        />
      </div>

      <div class="flex items-center justify-end mt-4">
        <PrimaryButton
          :class="{ 'opacity-25': newPasswordForm.processing }"
          :disabled="newPasswordForm.processing"
        >
          Reset Password
        </PrimaryButton>
      </div>
    </form>
  </AuthenticationCard>
</template>
