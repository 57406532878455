<template>
  <div class="bg-gray-100 min-h-screen w-full flex flex-col">
    <nav class="flex justify-end p-2 px-6 text-xl space-x-2 bg-black">
      <img
        src="../assets/DViO-white-logo-1.png"
        alt="logo"
        class="max-w-[25%] h-[30px] absolute left-0 ml-5"
      />
      <a
        href="/login"
        class="rounded-md px-3 py-2 text-sm font-medium hover:text-gray-800 bg-gray-900 text-white hover:bg-white"
      >
        Log in
      </a>

      <a
        href="/register"
        class="rounded-md px-3 py-2 text-sm font-medium hover:text-gray-800 hover:bg-white bg-gray-900 text-white"
      >
        Register
      </a>
    </nav>
    <main class="text-3xl p-4">
      <span class="text-black">Welcome to DVIO ONE</span>
    </main>
  </div>
</template>
