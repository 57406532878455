<template>
  <form @submit.prevent="logout">
    <button>Log Out</button>
  </form>
</template>

<script setup>
import { toast } from 'vue3-toastify'
import { useAuthStore } from '@/stores/authStore'
import { useUserStore } from '@/stores/userStore'
import { useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'
import { useTeamStore } from '@/stores/teamStore'
import { useConnectorStore } from '@/stores/connectorStore'

const authStore = useAuthStore()
const userStore = useUserStore()
const teamStore = useTeamStore()
const connectorStore = useConnectorStore()
const router = useRouter()
const queryClient = useQueryClient()
const logout = () => {
  authStore.logout()
  userStore.clearUser()
  teamStore.clearTeam()
  connectorStore.clearStore()
  queryClient.removeQueries([
    'users-metrics',
    'city-wise-conersions',
    'top-landing-pages',
    'ctr-metrics',
    'cpc-metrics',
    'impressions-and-clicks-metrics'
  ])
  router.push({ name: 'Login' })
  toast('Logged out successfully!', {
    theme: 'light',
    transition: 'zoom',
    type: 'success',
    autoClose: 1000,
    position: 'top-center',
    dangerouslyHTMLString: true
  })
}
</script>
