import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/pages/Auth/Login.vue'
import ProfileShow from '@/pages/Profile/Show.vue'
import TeamsShow from '@/pages/Teams/Show.vue'
import TeamsCreate from '../pages/Teams/Create.vue'
import Register from '@/pages/Auth/Register.vue'
import WelcomeView from '@/views/WelcomeView.vue'
import VerifyEmailOtp from '@/pages/Auth/VerifyEmailOtp.vue'
import { useAuthStore } from '@/stores/authStore'
import DashBoard from '@/views/DashBoardView.vue'
import InsightView from '@/views/InsightView.vue'
import ForgotPassword from '@/pages/Auth/ForgotPassword.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: WelcomeView,
      meta: {
        title: 'Home | DVIO ONE',
        requiresGuest: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Login | DVIO ONE',
        requiresGuest: true // Meta field to indicate that this route is for guests only
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        title: 'Register | DVIO ONE',
        requiresGuest: true // Meta field to indicate that this route is for guests only
      }
    },
    {
      path: '/verify',
      name: 'Verify',
      component: VerifyEmailOtp,
      meta: {
        title: 'Verify Yourself | DVIO ONE',
        requiresGuest: true // Meta field to indicate that this route is for guests only
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: DashBoard,
      meta: { requiresAuth: true, title: 'Dashboard | DVIO ONE' } // Meta field to indicate that this route is for authenticated user only
    },
    {
      path: '/insight',
      name: 'Insight',
      component: InsightView,
      meta: { requiresAuth: true, title: 'Insight | DVIO ONE' } // Meta field to indicate that this route is for authenticated user only
    },
    {
      path: '/profile',
      name: 'Profile',
      component: ProfileShow,
      meta: { requiresAuth: true, title: 'User Profile | DVIO ONE' } // Meta field to indicate that this route is for authenticated user only
    },
    {
      path: '/team',
      name: 'Team',
      component: TeamsShow,
      meta: { requiresAuth: true, title: 'Team Settings | DVIO ONE' } // Meta field to indicate that this route is for authenticated user only
    },
    {
      path: '/create-team',
      name: 'Create Team',
      component: TeamsCreate,
      meta: { requiresAuth: true, title: 'Add New Team | DVIO ONE' } // Meta field to indicate that this route is for authenticated user only
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword,
      meta: {
        title: 'Reset Password | DVIO ONE',
        requiresGuest: true // Meta field to indicate that this route is for guests only
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  authStore.checkAuth() // Ensure authentication check is completed

  // Check if user is authenticated and trying to access a route that requires authentication
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    return next({ name: 'Login' })
  }

  // Handle routes that require guests only
  if (to.meta.requiresGuest && authStore.isAuthenticated) {
    return next({ name: 'Dashboard' })
  }

  // Set the document title
  document.title = to.meta.title || 'DVIO ONE'
  next()
})

export default router
