import { defineStore } from 'pinia'

export const useTeamStore = defineStore('team', {
  state: () => ({
    current_team: null,
    teams: []
  }),
  actions: {
    setTeams(teams) {
      this.teams = [...teams]
      if (this.teams.length > 0) {
        this.setCurrentTeam(this.teams[0])
      }
    },
    setCurrentTeam(team) {
      this.current_team = team
    },
    addTeam(newTeam) {
      this.teams.push(newTeam)
      this.setCurrentTeam(newTeam)
    },
    clearTeam() {
      this.current_team = null
      this.teams = []
    }
  }
})
