import { defineStore } from 'pinia'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: !!cookies.get('authToken')
  }),
  actions: {
    login(accessToken, refreshToken) {
      this.isAuthenticated = true
      cookies.set('authToken', accessToken, '60s')
      cookies.set('refreshToken', refreshToken, '1d') // Set token in cookies, expires in 1 day
    },
    logout() {
      this.isAuthenticated = false
      cookies.remove('authToken') // Remove token from cookies
      cookies.remove('refreshToken')
    },
    checkAuth() {
      this.isAuthenticated = !!cookies.get('authToken')
    }
  }
})
