<script setup>
import { ref, watch } from 'vue';
import ActionMessage from '@/components/ActionMessage.vue';
import FormSection from '@/components/FormSection.vue';
import InputError from '@/components/InputError.vue';
import InputLabel from '@/components/InputLabel.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import TextInput from '@/components/TextInput.vue';
import OtpInput from '@/components/OtpInput.vue';
import axios from '@/utils/publicApiInstance';
import { toast } from 'vue3-toastify';

// Define the prop
const props = defineProps({
  email: {
    type: String,
    required: true,
  },
});

const newPasswordForm = ref({
  email: props.email,
  password: '',
  password_confirmation: '',
  otpcode: Array(6).fill(''),
  errors: {},
  processing: false,
  recentlySuccessful: false,
});
const showEmailForm = ref(true);
const showPasswordForm = ref(false);
watch(
  () => props.email,
  (newEmail) => {
    newPasswordForm.value.email = newEmail;
  },
);
const validate = () => {
  let isValid = true;
  // Password validation
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  if (!passwordPattern.test(newPasswordForm.value.password)) {
    newPasswordForm.value.errors.password =
      'Password does not satisfy conditions .';
    isValid = false;
  }

  // Password confirmation validation
  if (
    newPasswordForm.value.password !==
    newPasswordForm.value.password_confirmation
  ) {
    newPasswordForm.value.errors.password_confirmation =
      'Passwords do not match.';
    isValid = false;
  }
  if (newPasswordForm.value.otpcode.join('') == '') {
    newPasswordForm.value.errors.otp = true;
    isValid = false;
  }
  return isValid;
};
const requestPasswordReset = async () => {
  newPasswordForm.value.processing = true;
  newPasswordForm.value.errors = {};
  newPasswordForm.value.recentlySuccessful = false;

  try {
    const response = await axios.post('/auth/forgot-password', {
      email: newPasswordForm.value.email,
    });

    if (response.status == 200) {
      toast('Otp sent successfully', {
        type: 'success',
        position: 'top-center',
        autoClose: 1000,
        transition: 'zoom',
      });
    }
  } catch (error) {
    newPasswordForm.value.errors = error || {};
  } finally {
    newPasswordForm.value.processing = false;
    showEmailForm.value = false;
    showPasswordForm.value = true;
  }
};

const resetPassword = async () => {
  newPasswordForm.value.processing = true;
  newPasswordForm.value.errors = {};
  newPasswordForm.value.recentlySuccessful = false;
  if (!validate()) {
    newPasswordForm.value.processing = false;

    return; // Stop submission if validation fails
  }
  try {
    const otpString = newPasswordForm.value.otpcode.join('');
    const response = await axios.put('/auth/reset-password', {
      email: newPasswordForm.value.email,
      password: newPasswordForm.value.password,
      token: otpString,
    });
    if (response.status == 200) {
      toast('Password changed successfully', {
        type: 'success',
        position: 'top-center',
        autoClose: 1000,
        transition: 'zoom',
      });
    }

    newPasswordForm.value.recentlySuccessful = true;
    newPasswordForm.value.password = '';
    newPasswordForm.value.password_confirmation = '';
    newPasswordForm.value.otpcode = Array(6).fill('');
  } catch (error) {
    newPasswordForm.value.errors = error.response.data.errors || {};
    console.log(error);

    if (error.response.status == 400) {
      toast(error.response.data.detail, {
        type: 'error',
        position: 'top-center',
        autoClose: 1000,
        transition: 'zoom',
      });
    }
  } finally {
    newPasswordForm.value.processing = false;
    showEmailForm.value = true;
    showPasswordForm.value = false;
  }
};
</script>

<template>
  <FormSection v-if="showEmailForm" @submitted="requestPasswordReset">
    <template #title> Change Password </template>

    <template #description>
      You will receive a OTP on following email to change your password
    </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <InputLabel for="email" value="Email" />
        <TextInput
          id="email"
          v-model="newPasswordForm.email"
          type="email"
          class="mt-1 block w-full"
          autocomplete="email"
          :disabled="true"
        />
        <InputError :message="newPasswordForm.errors.email" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <PrimaryButton
        :class="{ 'opacity-25': newPasswordForm.processing }"
        :disabled="newPasswordForm.processing"
      >
        Request OTP
      </PrimaryButton>
    </template>
  </FormSection>

  <FormSection v-if="showPasswordForm" @submitted="resetPassword">
    <template #title> Reset Password </template>

    <template #description> Enter your new password to reset it. </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <InputLabel for="password" value="New Password" />
        <TextInput
          id="password"
          v-model="newPasswordForm.password"
          type="password"
          class="mt-1 block w-full"
          autocomplete="new-password"
        />
        <InputError :message="newPasswordForm.errors.password" class="mt-2" />
      </div>

      <div class="col-span-6 sm:col-span-4">
        <InputLabel for="password_confirmation" value="Confirm Password" />
        <TextInput
          id="password_confirmation"
          v-model="newPasswordForm.password_confirmation"
          type="password"
          class="mt-1 block w-full"
          autocomplete="new-password"
        />
        <InputError
          :message="newPasswordForm.errors.password_confirmation"
          class="mt-2"
        />
      </div>
      <div class="col-span-6 sm:col-span-4">
        <OtpInput
          v-model:otpcode="newPasswordForm.otpcode"
          :error="!!newPasswordForm.errors.otp"
          :errorMessage="newPasswordForm.errors.otp ? 'Please Enter OTP' : ''"
        />
      </div>
    </template>

    <template #actions>
      <ActionMessage :on="newPasswordForm.recentlySuccessful" class="me-3">
        Password has been reset.
      </ActionMessage>

      <PrimaryButton
        :class="{ 'opacity-25': newPasswordForm.processing }"
        :disabled="newPasswordForm.processing"
      >
        Reset Password
      </PrimaryButton>
    </template>
  </FormSection>
</template>
