<!-- src/components/PaginatedTable.vue -->
<template>
  <div class="container mx-auto p-4">
    <span class="text-xl font-semibold dark:text-white">
      {{ title }} <span class="text-lg">({{ subtitle }})</span>
    </span>

    <table
      class="min-w-full bg-white mt-4 dark:bg-black border dark:border-gray-500"
    >
      <thead>
        <tr
          class="border-b border-gray-200 text-gray-800 dark:text-white text-left"
        >
          <th class="p-2 pl-6">Rank</th>
          <th class="p-2">{{ column1Title }}</th>
          <th class="p-2">{{ column2Title }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in displayedData"
          :key="index"
          class="text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors border-b border-gray-200"
        >
          <td class="p-2 pl-8">{{ startIndex + index + 1 }}</td>
          <td class="p-2">{{ item[0] }}</td>
          <td class="p-2">{{ formatter ? formatter(item[1]) : item[1] }}</td>
        </tr>
      </tbody>
    </table>

    <div class="flex justify-between mt-4 text-sm">
      <button
        class="px-2 py-2 border text-black dark:text-white rounded-md"
        @click="prevPage"
        :disabled="currentPage === 1"
      >
        Previous
      </button>
      <span class="self-center"
        >Page {{ currentPage }} of {{ totalPages ? totalPages : 0 }}</span
      >
      <button
        class="px-2 py-2 border text-black dark:text-white rounded-md"
        @click="nextPage"
        :disabled="currentPage === totalPages"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import axios from '../utils/privateApiInstance';
import { useTeamStore } from '@/stores/teamStore';

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
    deep: true,
  },
);
// Props
const props = defineProps({
  title: String,
  subtitle: String,
  column1Title: String,
  column2Title: String,
  apiUrl: String,
  queryKey: String, // Include queryKey as a prop
  formatter: Function, // Add formatter prop
});

const itemsPerPage = 5;
const currentPage = ref(1);

const fetchData = async () => {
  try {
    const response = await axios.get(props.apiUrl, {
      params: {
        team_id: currentTeam.value?.id || 1,
      },
    });
    return JSON.parse(response.data?.items.data);
  } catch (error) {
    console.error('Error fetching data');
    return [];
  }
};

const { data: apiData } = useQuery({
  queryKey: [props.queryKey],
  queryFn: fetchData,
  staleTime: 10 * 60 * 1000,
  cacheTime: 15 * 60 * 1000,
  refetchInterval: 10 * 60 * 1000,
});

const totalPages = computed(() =>
  Math.ceil(apiData.value?.length / itemsPerPage),
);
const startIndex = computed(() => (currentPage.value - 1) * itemsPerPage);

const displayedData = computed(() =>
  apiData.value?.slice(startIndex.value, startIndex.value + itemsPerPage),
);

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};
</script>
