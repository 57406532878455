<template>
  <MetricCard
    :fromDate="fromDate"
    :toDate="toDate"
    apiEndpoint="/google-ads/cost-per-click"
    metricName="CPC"
    :formatData="formatCPC"
    srcName="Google Ads"
  />
</template>

<script setup>
import MetricCard from '../MetricCard.vue';

const props = defineProps({
  fromDate: String,
  toDate: String,
});

const formatCPC = (data) => '₹' + data.data.toFixed(2);
</script>
